.ant-modal-content {
    background-color: black;
    border-radius: 8px;
    border: 3px solid white;
    width: 100% !important;
}

.ant-modal-footer {
    display: none;
}

.ant-modal-close-x {
    color: white;
}

.ant-modal-header {
    background-color: black;
}

.ant-modal-title {
    color: white;
}

#app { height: 100% }

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    background-position: center;
    background-size: cover;
}

.swiper-slide img {
    display: block;
    width: 100%;
    object-fit: contain;
}

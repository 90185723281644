@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+KR:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');
@font-face {
  font-family: "Helvetica";
  src: url("assets/fonts/Helvetica.ttf");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Helvetica";
  src: url("assets/fonts/Helvetica Bold.ttf");
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: "Helvetica";
  src: url("assets/fonts/Helvetica Light.ttf");
  font-style: normal;
  font-weight: 300;
}
@import '~antd/dist/antd.css';

html, body {
  margin: 0;
  padding: 0;
  background-color: black;
  overflow-x: hidden;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Noto Sans KR', sans-serif;
}